:root {
	--opacityArticleTitle: 0;
}

body {
	margin: 0;
	padding: 0;
	border: 0;
	/* background-color: #20CE99; */
	/* background-color: #006464; */
	/* background: linear-gradient(90deg, rgba(0,57,100,1) 0%, rgba(0,100,100,1) 68%); */
	background-color: black;
	background-image: none;
	/* background-image: linear-gradient(90deg, rgb(44, 32, 38) 0%, rgb(91, 98, 107) 100%); */

	transition: background-color .3s ease-out;
	color: white;
	min-height: 100vh;

	overflow-y: scroll;
	overflow-x: hidden;
}

body {
	cursor: url("../static/images/cursors/cursor_new.svg") 7 7, default;
}

.circle {
	visibility: hidden;

	position: absolute;
	height: 13px;
	width: 13px;
	background-color: rgba(255, 255, 255, .15);
	border-radius: 50%;
	z-index: 0;

	transition-duration: 150ms;
	transition-timing-function: ease-out;
	transform: translate(-50%, -50%);
}

a:hover,
button:hover {
	/* cursor: url("../static/images/cursors/cursor_new_backdrop_small.svg") 30 30, default; */
	cursor: none;
}

img {
	-webkit-user-drag: none;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

::selection {
	background-color: rgba(255, 255, 255, .8);
	color: black;
}

.container {
	max-width: 1440px;
	margin: 0 auto;
}

.container-wide {
	max-width: 1920px;
	margin: 0 auto;
}


/* LOGO
 =================================== */

.logo {
	margin: 50px 0 40px;
}

.logo a {
	padding: 50px 0;
	color: inherit;
	text-decoration: none;
}

.logo img {
	max-height: 45px;
	opacity: .7;
	transition: none;
}

.logo a:hover {
	/* cursor: url("../static/images/cursors/cursor_new_backdrop.svg") 60 60, auto; */
	cursor: none;
}

.logo a:hover img {
	opacity: 1;
}

.logo a:focus img,
.logo a:active img {
	border: 1px double #444444;
	padding: 15px 20px 8px;
	margin: -16px 5px -9px -21px;
	transition: none;
	/* cursor: url("../static/images/cursors/cursor_new_backdrop_focus.svg") 60 60, auto; */
	cursor: none;
}


/* FONTS
 =================================== */

/* Headings */

@font-face {
	font-family: 'Sharp Grotesk DB Cyr Medium 22';
	src: url("../static/fonts/Sharp-Grotesk-DB-Cyr-Medium.otf") format("opentype");
}

/* General */

@font-face {
	font-family: 'SF Pro Display Heavy';
	src: url("../static/fonts/SFProDisplay-Heavy.otf") format("opentype");
}

@font-face {
	font-family: 'SF Pro Display Medium';
	src: url("../static/fonts/SFProDisplay-Medium.otf") format("opentype");
}

@font-face {
	font-family: 'SF Pro Display Regular';
	src: url("../static/fonts/SFProDisplay-Regular.otf") format("opentype");
}

@font-face {
	font-family: 'SF Pro Display Thin';
	src: url("../static/fonts/SFProDisplay-Thin.otf") format("opentype");
}


/* PROMO SECTION
 =================================== */

.promo {
	position: relative;
	background: none;
	border-radius: 15px;
	margin: 1rem auto 3rem;
	padding: 40px 0;
	z-index: 49;
	height: calc(100vh - 240pt);
}

.promo h1 {
	position: absolute;
	text-align: left;
	left: 0;
	bottom: 0;
	margin: 0;
	font-size: 120pt;
	line-height: 125pt;
	max-width: 1150px;
	overflow: hidden;

	font-family: 'Sharp Grotesk DB Cyr Medium 22', serif;
	font-weight: bold;
	color: white;
}

.promo h1 .wordparent {
	position: relative;
	overflow: hidden;
	margin: 0;
	display: inline-block;
	padding: 0 40px 20px 0;
}

.promo h1 .word {
	position: relative;
	margin: 0;
	display: inline-block;
}

.promo h1 .wordchild {
	position: relative;
	display: inline-block;
}


.promo button {
	display: none;
	font-size: 16pt;
	text-transform: none;
	padding: 15px 30px;
	margin: 2rem 0 10vh;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	background-color: #ffffff;
}

.promo button img {
	height: 1.2rem;
	margin-bottom: -.11rem;
	margin-left: .25rem;
}

.promo button:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop.svg") 60 60, auto;
	opacity: .8;
}

.promo button:active,
.promo button:focus {
	opacity: .4;
}

.promo > img {
	position: absolute;
	margin: -32.5rem 0 0 36rem;
	transform: scaleX(-1) scale(1.3);
}


/* DASHBOARD SECTION
 =================================== */

.dashboard {
	margin-top: 10px;
}

.dashboard .scrolldown {
	margin: 0 auto 25px;
}

.dashboard .scrolldown img {
	height: 100px;
}

.dashboard > .card {
	background-color: #222222;
	border-radius: 15px;
	padding: 40px 50px 45px;
	transform: scale(1);
}

.dashboard > .card .description {
	opacity: .7;
	transition: opacity .2s;
}

.dashboard > .card:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_right.svg") 60 60, auto;
	z-index: 99;
}

.dashboard > .card:focus,
.dashboard > .card:active {
	cursor: url("../static/images/cursors/cursor_new_backdrop.svg") 60 60, auto;
	z-index: 99;

	transform: scale(.99);
	transition: .2s transform;
}

.dashboard > .card.article.article-big > div.thumbnail {
	max-width: calc(100% + 20px);
	border-radius: 15px 0 0 15px;
	margin: -40px 0 -40px -50px;
	background-position: center;
	background-size: 1000px;
	height: calc(100% + 80px);
	transition: background-size .25s;
}

.dashboard > .card.article.article-big:hover > div.thumbnail {
	background-size: 1025px;
	transition: background-size .25s;
}

.dashboard > .card.article > div.thumbnail {
	max-width: calc(100% + 200px);
	height: 300px;
	border-radius: 15px 15px 0 0;
	margin: -40px -50px -40px -50px;
	background-position: center;
	background-size: 1000px;
	transition: background-size .25s;
}

.dashboard > .card.article:hover > div.thumbnail {
	background-size: 1025px;
	transition: background-size .25s;
}

.dashboard > .card.article.span-1 > div.thumbnail {
	background-size: 500px;
	transition: background-size .25s;
}

.dashboard > .card.article.span-1:hover > div.thumbnail {
	background-size: 525px;
	transition: background-size .25s;
}

.dashboard > .separator {
	opacity: .8;
	width: calc(100% - 20px);
	text-align: center;
	border-top: 1px solid #555555;
	line-height: 0;
	margin: 15px 0 18px 10px;
}

.dashboard > .separator span {
	padding: 0 15px;
	background-color: black;
	font-family: 'SF Pro Display Regular', sans-serif;
	color: #777777;
	text-transform: uppercase;
}

.dashboard > .card.about {
	background-size: cover;
}

.dashboard > .card.future h2 {
	padding-top: .75rem;
	line-height: 3.75rem;
}

.dashboard > .card img#cover_webdev {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 15px;
	height: 450px;
	z-index: -1;
	opacity: .4;
	filter: blur(5px);
}

.dashboard > .card img#cover_design {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 15px;
	height: 480px;
	z-index: -1;
	opacity: .2;
	filter: blur(5px);
}

.dashboard > .card img#cover_stories {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 15px;
	height: 360px;
	z-index: -1;
	opacity: .1;
	filter: blur(0px);
}

.dashboard > .card img#cover_future {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 15px;
	width: 100%;
	z-index: -1;
	opacity: .125;
}

.dashboard > .card img#cover_charity {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 15px;
	width: 100%;
	z-index: -1;
	opacity: .125;
}

.dashboard > .card img#cover_windmill {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 15px;
	width: 100%;
	z-index: -1;
	opacity: .125;
}

.dashboard > .card h2 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	line-height: 57pt;
	font-size: 45pt;
	margin: 0;
	hyphens: auto;
	word-wrap: break-word;
}

.dashboard > .card.article.article-big h3 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 36pt;
	line-height: 47pt;
	color: white;
	margin: 3px 0 40px -280px;
	background-color: #1F1F1F;
	box-shadow: #111111 5px 10px 100px;
	padding: 30px 40px;
	border-radius: 15px;
}

.dashboard > .card.article.article-big .details {
	margin: -.8rem 0 1.25rem;
}

.dashboard > .card.article h3 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 29pt;

	margin: 1.25rem 0;
	line-height: 42pt;
	color: white;
	border-radius: 15px;
}

.dashboard > .card p {
	font-family: 'SF Pro Display Medium', sans-serif;
	color: #BFBFBF;
	max-width: 700px;
	font-size: 14pt;
}

.dashboard > .card.article p {
	max-width: none;
	font-family: 'SF Pro Display Regular', sans-serif;
}

.dashboard > .card.article .details {
	display: block;
	margin: 4.2rem 0 1.25rem;
}

.dashboard > .card.article .details ul.tags {
	display: inline-block;
	list-style-type: none;
	margin: 0 0 -10px;
	padding: 0;
}

.dashboard > .card.article .details ul.tags li {
	display: inline-block;
	margin: 0 10px 10px 0;
	padding: 5px 15px;
	background-color: rgba(255, 255, 255, .75);
	color: black;
	font-family: 'SF Pro Display Heavy', sans-serif;
	border-radius: 5px;
	backdrop-filter: blur(15px);
}

.dashboard > .card.article .timestamp p {
	margin: 0;
	padding: 0;
	text-transform: uppercase;
	font-family: 'SF Pro Display Heavy', sans-serif;
	color: #555555;
	letter-spacing: .05rem;
}


.dashboard > .card p.cutoff {
	max-height: 250px;
	overflow-y: scroll;
	padding-right: 20px;
}

.dashboard > .card p.cutoff p {
	margin-top: 0;
}

.dashboard > .card p.cutoff.long {
	max-height: 300px;
}

.dashboard > .card p.cutoff::-webkit-scrollbar-track {
	background: #666666;
	border-radius: 15px;
}

.dashboard > .card p.cutoff::-webkit-scrollbar-thumb {
	background: #999999;
	border-radius: 15px;
}

.dashboard > .card p.cutoff::-webkit-scrollbar-thumb:hover {
	background: white;
}

.dashboard > .card p.cutoff::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 15px;
	background: linear-gradient(transparent 250px, rgba(34, 34, 34, 1));
}

.dashboard > .card p.cutoff.stdsmall::before {
	background: linear-gradient(transparent 450px, rgba(34, 34, 34, 1));
}

.dashboard > .card p.cutoff.long::before {
	background: linear-gradient(transparent 500px, rgba(34, 34, 34, 1));
}

.dashboard > .card p.cutoff.blue::before {
	background: linear-gradient(transparent 250px, rgba(24, 38, 58, 1));
}

.dashboard > .card p.tags {
	color: rgba(255, 255, 255, .45);
	font-family: 'SF Pro Display Thin', sans-serif;
}


/* Grid System */

.grid {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(3, 1fr);
}

.grid .span-2 {
	grid-column: span 2;
}

.grid .span-3 {
	grid-column: span 3;
}


/* HEADER
 =================================== */

header {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	position: relative;
	z-index: 99;
}

.nav {
	margin-top: 2.5rem;
}

.nav ul {
	list-style-type: none;
	padding: 0;
	white-space: nowrap;
	margin: -5px -20px 0 0;
}


.nav .hamburger {
	position: relative;
	width: 50px;
	height: 50px;
	margin: .35rem .5rem 0 0;
	cursor: pointer;
	transform: scale(-1.2, 1.2);
	z-index: 999;
	display: none;
}

.nav .hamburger div {
	margin: auto;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 22px;
	height: 12px;
	pointer-events: none;
}

.nav .hamburger span {
	position: absolute;
	display: block;
	width: 100%;
	height: 3px;
	background-color: #D8D8D8;
	border-radius: 1px;
	transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}

.nav .hamburger span:first-of-type {
	top: 0;
}

.nav .hamburger span:last-of-type {
	bottom: 0;
}

.nav .hamburger.active {
	position: fixed;
	right: 48px;
}

.nav .hamburger.active span:first-of-type {
	transform: rotate(45deg);
	top: 5px;
	background-color: white;
}

.nav .hamburger.active span:last-of-type {
	transform: rotate(-45deg);
	bottom: 4px;
	background-color: white;
}

.nav .hamburger.active:hover span:first-of-type,
.nav .hamburger.active:hover span:last-of-type {
	width: 22px;
}

.nav .hamburger:hover span {
	background-color: white;
}

@media (min-width: 1024px) {
	.nav .hamburger:hover span:first-of-type {
		width: 24px;
	}
	.nav .hamburger:hover span:last-of-type {
		width: 14px;
	}
}

.mobile-nav {
	display: none;
	position: fixed;
	transform: translateX(475px);
	background-color: rgba(34, 34, 34, .4);
	backdrop-filter: blur(44px);
	padding: 7rem 6rem 0 2rem;
	height: 100%;
	right: 0;
	overflow: hidden;
	z-index: 399;
}

.mobile-nav ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.mobile-nav ul li {
	padding: 0;
	margin: 0;
}

.mobile-nav ul li a {
	color: #F0F0F0;
	display: block;
	text-decoration: none;
	font-family: 'SF Pro Display Medium', sans-serif;
	font-size: 18pt;
	letter-spacing: .1rem;
	padding: 25px;
	text-transform: uppercase;
}


.nav ul li {
	display: inline-block;
}

.nav ul li a {
	display: block;
	color: #C6C6C6;
	padding: 25px;
	text-decoration: none;
	text-transform: uppercase;
	font-family: 'SF Pro Display Regular', sans-serif;
	letter-spacing: .5pt;
	font-size: 18pt;
	text-shadow: none;
}

.nav ul li a:hover sup {
	background-color: white;
	transform: translateY(-2px);
	transition: transform .2s;
}

.mobile-nav ul li a sup,
.nav ul li a sup {
	background-color: #C6C6C6;
	color: black;
	font-size: 9pt;
	letter-spacing: .1pt;
	font-family: 'SF Pro Display Heavy', sans-serif;
	padding: 4px 8px;
	border-radius: 4px;
	vertical-align: .3rem;
	margin-left: .75rem;
	transition: transform .15s;
}

.mobile-nav ul li a sup {
	margin: .18rem 0 0 1rem;
	background-color: #F0F0F0;
}


/* -- Change font for active item
.nav ul li a.active::before,
.nav ul li a.active span {
	font-family: 'Montserrat', sans-serif;
}
*/

.nav ul li a span {
	display: block;
	pointer-events: none;
	-webkit-transition: transform .3s, opacity .15s;
	-moz-transition: transform .3s, opacity .15s;
	transition: transform .3s, opacity .15s;
}

.nav ul li a:hover {
	/* cursor: url("../static/images/cursors/cursor_new_backdrop.svg") 60 60, auto; */
	cursor: none;
}

.nav ul li a.active,
.nav ul li a:active,
.nav ul li a:focus {
	/* cursor: url("../static/images/cursors/cursor_new.svg") 30 30, auto; */
	cursor: none;
}

.nav ul li a.active span,
.nav ul li a:hover span,
.nav ul li a:focus span {
	color: #F2F2F2;
	display: block;
	transform: translateY(-.375em);
	-webkit-transition: transform .2s ease, opacity .15s;
	-moz-transition: transform .2s ease, opacity .15s;
	transition: transform .2s ease, opacity .15s;
	mix-blend-mode: difference;
}

.nav ul li a::before {
	display: block;
	opacity: 0;
	color: white;
	content: attr(data-hover);
	position: absolute;
	transform: scale(1, -1);
	-webkit-transition: -webkit-transform .3s, opacity .15s;
	-moz-transition: -moz-transform .3s, opacity .15s;
	transition: transform .3s, opacity .15s;
}

.nav ul li a.active::before,
.nav ul li a:hover::before,
.nav ul li a:focus::before {
	display: block;
	transform: translateY(.375em) scale(1, -1);
	opacity: .4;
	-webkit-transition: -webkit-transform .2s ease-out, opacity .15s;
	-moz-transition: -moz-transform .2s ease-out, opacity .15s;
	transition: transform .2s ease-out, opacity .15s;
	mix-blend-mode: difference;
}


/* PORTFOLIO
 =================================== */

.portfolio .intro {
	display: flex;
	margin: 7.5rem 0 0 21rem;
}

.portfolio .intro img {
	width: 35rem;
	height: 100%;
	filter: grayscale(.2);
	overflow: hidden;
	position: relative;
	z-index: 99;
	display: inline-block;
	transition: .2s filter;
	border-radius: 15px;
	background-color: #444444;
}

.portfolio .intro img:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_magnify.svg") 60 60, auto;
	filter: none;
	transition: .2s filter;
}

.portfolio .intro .title {
	margin-left: 5rem;
	margin-top: -5rem;
}

.portfolio .intro .title h1:last-of-type {
	margin-top: -10rem;
}

.portfolio .intro .title h1 {
	position: relative;

	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 115pt;
	max-width: 780px;
	line-height: 117pt;
}

.portfolio .intro .title h1 .letter {
	display: inline-block;
	opacity: 0;
}

.portfolio .intro .title p.roles {
	position: relative;
	margin-top: -6rem;
	margin-left: .75rem;

	font-family: 'SF Pro Display Thin', sans-serif;
	font-size: 32pt;
	letter-spacing: .15rem;
	text-transform: uppercase;
}

.portfolio .intro .title p .role {
	position: relative;
	display: block;
	visibility: hidden;
}

.portfolio .intro .title p .role a {
	text-decoration: none;
	color: inherit;
	opacity: .6;
	display: block;
	transition: .2s transform;
}

.portfolio .intro .title p .role a:hover,
.portfolio .intro .title p .role a.active {
	color: white;
	transform: translateX(10px);
	transition: .2s transform;
}


/* Portfolio Sections */

.portfolio > section.intro {
	margin-bottom: -20rem;
}

.portfolio > section:not(.intro) {
	display: flex;
	height: 30rem;
	margin-top: 40rem;
	transition: transform .5s;
}

.portfolio > section:not(.intro) .image img {
	cursor: url("../static/images/cursors/cursor_new_backdrop_magnify.svg") 60 60, auto;
	width: 35rem;
	height: auto;
	filter: grayscale(.5);
	transition: .2s filter;
	border-radius: 15px;

	position: relative;
	z-index: 99;
}

.portfolio > section:not(.intro) .image img:hover {
	filter: none;
	transition: .2s filter;
}

.portfolio > section:not(.intro) .title h2 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 85pt;
	letter-spacing: -.05rem;
	line-height: 85pt;
	margin-top: 3rem;
}

.portfolio > section:not(.intro) .title .description {
	display: inline-block;
	font-size: 20pt;
	margin-top: -1rem;
	font-family: 'SF Pro Display Regular', sans-serif;
}

.portfolio > section:not(.intro) .title .logos img {
	padding: 10px 15px;
	max-height: 60px;
	width: auto;
}

.portfolio > section:not(.intro) .title .logo-disclaimer {
	text-transform: uppercase;
	font-family: 'SF Pro Display Heavy', sans-serif;
	color: #666666;
	display: block;
	margin: 0 0 1rem;
}

.portfolio > section.webdev .title .logos img:hover {
	filter: grayscale(.25);
}

.portfolio > section:last-child {
	margin-bottom: 7.5rem;
}


/* Portfolio Section: Right */

.portfolio .right {
	margin-right: 10rem;
}

.portfolio .right .image {
	width: 60rem;
}

.portfolio .right .title {
	padding: 0 5rem;
}

.portfolio .right .title h2 {
	text-align: right;
}

.portfolio .right .title .description {
	color: #DFDFDF;
	float: right;
	margin-right: -1.25rem;
}

.portfolio .right .title .logo-disclaimer {
	text-align: right;
	padding-right: 1rem;
}

.portfolio .right .title .logos {
	margin: 2.5rem .5rem 0 0;
	float: right;
}


/* Portfolio Section: Left */

.portfolio .left {
	display: flex;
	margin-left: 20rem;
}

.portfolio .left .image {
	width: 36rem;
}

.portfolio .left .title {
	padding: 0 5rem;
}

.portfolio .left .title .description {
	margin-left: 4.6rem;
	color: #DFDFDF;
}

.portfolio .left .title .logo-disclaimer {
	padding-left: 1rem;
}

.portfolio .left .title .logos {
	margin: 2.5rem 0 0 -.3rem;
}

.portfolio .left .title .logos img {
	padding: 10px 15px !important;
	max-height: 55px !important;
}


/* Portfolio Section: Customers */

.portfolio section.customers,
.portfolio section.impressions {
	position: relative;
	display: block;
	width: 100%;
}

.portfolio section.customers h3,
.portfolio section.impressions h3 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 45pt;
	text-align: center;
	margin: 0 0 2.5rem;
}

.portfolio section.customers ul {
	list-style-type: none;
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
}

.portfolio section.customers ul li {
	display: inline-block;
	opacity: .7;
	padding: 30px 30px;
	transition: transform .125s;
}

.portfolio section.customers ul li a {
	display: block;
	height: 80px;
}

.portfolio section.customers ul li:hover {
	opacity: 1;
	transform: translateY(-6px);
	transition: transform .125s;
}

.portfolio section.customers ul li img {
	height: 80px;
	width: auto;
}

.portfolio section.customers h4 {
	margin: 8rem 0 1rem;
	text-align: center;
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	color: #D6D6D6;
	font-size: 21pt;
	font-weight: normal;
}

.portfolio section.customers p {
	text-align: center;
	font-family: 'SF Pro Display Regular', sans-serif;
	color: #888888;
	font-size: 16pt;
	font-weight: normal;
}

.portfolio section.customers button {
	font-size: 16pt;
	text-transform: none;
	padding: 15px 30px;
	margin: 2rem 0 10vh;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	background-color: #ffffff;
}

.portfolio section.customers button img {
	height: 1.2rem;
	margin-bottom: -.11rem;
	margin-left: .25rem;
}


/* Portfolio Section: Impressions */

.portfolio section.impressions {
	margin: 35rem 0 20rem;
}

.portfolio section.impressions .carousel {
	list-style-type: none;
	white-space: nowrap;
	overflow-x: scroll;
	padding: 0;
	margin: 4rem 0;
}

.portfolio section.impressions .carousel::-webkit-scrollbar {
	width: 10px;
}

.portfolio section.impressions .carousel::-webkit-scrollbar-track {
	background-color: #111111;
	border-radius: 15px;
	margin: 0 32rem;
}

.portfolio section.impressions .carousel::-webkit-scrollbar-thumb {
	background: #444444;
}

.portfolio section.impressions .carousel::-webkit-scrollbar-thumb:hover {
	background: #555555;
}

.portfolio section.impressions .carousel li {
	display: inline-block;
	background-color: gray;
	height: 400px;
	width: 750px;
	margin: 25px -5px 75px;
	transform: scale(.95);

	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;

	padding: 30px 45px;
	transition: .15s;

	opacity: .4;
}

.portfolio section.impressions .carousel li:first-child {
	margin-left: 0;
}

.portfolio section.impressions .carousel li:last-child {
	margin-right: 0;
}

.portfolio section.impressions .carousel li.active {
	transform: scale(1.1);
	margin-left: 60px;
	margin-right: 60px;
	opacity: 1;
	transition: opacity .15s, transform .5s;
}

.portfolio section.impressions .carousel li.previous:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_left.svg") 60 60, auto;
}

.portfolio section.impressions .carousel li.next:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_right.svg") 60 60, auto;
}

.portfolio section.impressions .carousel li:hover {
	opacity: .45;
}

.portfolio section.impressions .carousel li.active:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_magnify.svg") 60 60, auto;
	opacity: .9;
}

.portfolio section.impressions .carousel li h5 {
	padding: 30px 45px;
	margin: 0;
	font-weight: normal;
	font-family: 'SF Pro Display Heavy', sans-serif;
	color: white;
	letter-spacing: .25pt;
	text-transform: uppercase;
	font-size: 13pt;

	position: absolute;
	bottom: 0;
	right: 0;

	text-shadow: 0 0 20px black;
}


/* STORIES
 =================================== */

.notfound .header,
.stories .header {
	margin-top: -3rem;
}

.notfound .header h1,
.stories .header h1 {
	display: inline-block;
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 92pt;
	background-color: black;
	padding: 0 75px;
	margin-left: -75px;
}

.notfound .header h1 .letter,
.stories .header h1 .letter {
	display: inline-block;
}

.notfound .header hr,
.stories .header hr {
	position: absolute;
	margin: -12.75rem 0 15rem;
	z-index: -1;
	left: 0;
	width: 99.85%;
	overflow: hidden;
	border: 1px solid #666666;
}


/* STORY (ARTICLE)
 =================================== */

.story {
	display: block;
	position: relative;
}

.story .header {
	margin-top: 4rem;
}

.story .header h1 {
	position: relative;
	display: block;
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 92pt;
	margin-left: -12rem;
	margin-top: 10rem;
	color: white;
	z-index: 399;
	max-width: 1150px;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

.story .header .phantom {
	position: absolute;
	display: block;
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 92pt;
	margin-top: -18rem;
	color: black;
	opacity: var(--opacityArticleTitle);
	text-shadow: 0 0 2px #181818,
		0 0 2px #181818,
		0 0 2px #181818,
		0 0 2px #181818;
	z-index: 0;
	pointer-events: none;
	user-select: none;
	max-width: 1100px;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* number of lines to show */
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

.story .header h1:after {
	position: absolute;
	z-index: 0;
}

.story .header .word {
	display: inline-block;
}

.story .header .image {
	border-radius: 15px;
	margin-left: 30rem;
	margin-top: -45rem;
	transition: filter .2s;
	filter: grayscale(.3);
	width: 1150px;
	height: 765px;
	object-fit: cover;
}

.story .header .image:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_magnify.svg") 60 60, auto;
	filter: grayscale(0);
	transition: filter .2s;
}

.story .header .author {
	position: absolute;
	z-index: 99;
	background-color: rgba(41, 41, 41, .6);
	display: inline-block;
	padding: 15px 18px;
	border-radius: 15px;
	margin: -6.75rem 0 0 12rem;
	transition: background-color .05s;
}

.story .header .author:hover {
	background-color: rgba(31, 31, 31, 1);
	transition: background-color .05s;
}

.story .header .author:focus,
.story .header .author:active {
	outline: 3px solid #444444;
	transition: background-color .05s;
}

.story .header .author img {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	object-fit: cover;
	vertical-align: middle;
}

.story .header .author p small img {
	filter: invert(1);
	position: absolute;
	opacity: .4;
	border-radius: 0;
	height: 12px;
	width: 12px;
	margin-left: 4px;
	margin-top: -.156rem;
}

.story .header .author p {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-weight: normal;
	display: inline-block;
	margin: 0 17px;
	vertical-align: -.8rem;
	font-size: 13pt;
	text-align: left;
	line-height: .55rem;
}

.story .header .author small {
	font-family: 'SF Pro Display Thin', sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	color: #999999;
	display: inline-block;
	font-size: 9pt;
}

.story .article-content {
	padding-top: 5rem;
	margin: 5rem 5rem;
	font-family: 'SF Pro Display Thin', sans-serif;
	font-size: 18pt;
	line-height: 2.35rem;
	letter-spacing: .075rem;
	color: #D4D4D4;
}

.story .readmore hr {
	position: absolute;
	display: inline-block;
	margin: 2.5rem 0;
	z-index: -1;
	left: 0;
	width: 100%;
	overflow: hidden;
	border: 1px solid #666666;
}

.story .readmore h3 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-weight: normal;
	font-size: 45pt;
	margin: 0;
	background-color: black;
	display: inline-block;
	padding: 0 2rem 0 0;
}


/* Article Meta Information */

.story .meta {
	display: block;
	margin: 2.5rem 5rem 8rem;
}

.story .meta > div {
	display: inline-block;
	position: relative;
	z-index: 99;
	background-color: #222222;
	padding: 30px 40px;
	border-radius: 15px;
	max-width: 50%;
	margin: 0 1.5rem 1.5rem 0;
}

.story .meta > div:hover {
	background-color: #191919;
}

.story .meta > div:nth-child(1):hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_right.svg") 60 60, pointer;
}

.story .meta > div:focus,
.story .meta > div:active {
	transform: scale(.99);
}

.story .meta .author-card img {
	border-radius: 50%;
	height: 70px;
	width: 70px;
	object-fit: cover;
	vertical-align: -.35rem;
}

.story .meta .author-card .details {
	display: inline-block;
	margin: 0 2rem;
}

.story .meta > div .details h3 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-weight: normal;
	font-size: 25pt;
	padding: 0;
	margin: -.25rem 0 .5rem;
}

.story .meta > div .details p {
	font-family: 'SF Pro Display Heavy', sans-serif;
	text-transform: uppercase;
	color: #888888;
	padding: 0;
	margin: 0;
	font-size: 13pt;
}

/* Read more section */

.stories.readmore {
	margin: 7rem auto 4rem;
}

/* DESIGN LABS
 =================================== */

.labs .background {
	position: absolute;
	height: 200%;
	width: 100%;
	right: 0;
	top: -17rem;

	background-position: -510px 60px;
	background-size: 120%;
	z-index: -99;
	filter: brightness(.8);
}

.labs .background::after {
	position: relative;
	width: 100%;
	height: 100%;
	content: '';
	background: linear-gradient(180deg, rgba(0,0,0,0) 45%, rgba(30,40,65,1) 80%);
	display: block;
}

.labs .header {
	margin-top: 5rem;
}

.labs .header h1 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	font-size: 69pt;
	letter-spacing: -.1rem;
	color: white;
	mix-blend-mode: overlay;
	text-align: right;
	padding-top: 1.25rem;
	margin-bottom: 2rem;
}

.labs .header p {
	text-align: right;
	font-family: 'SF Pro Display Thin', sans-serif;
	color: white;
	mix-blend-mode: overlay;
	font-size: 14pt;
}

.labs .disclaimer {
	position: relative;
	display: block;
	float: right;
	width: 350px;
	text-align: center;
	font-family: 'SF Pro Display Thin', sans-serif;
	letter-spacing: .5pt;
	color: rgba(255, 255, 255, .9);
	background-color: rgba(0, 0, 0, .3);
	border-radius: 25px;
	padding: 12px 25px;
	font-size: 15pt;
	backdrop-filter: blur(30px);
}


/* CLIMATE
 =================================== */

.climate .heading h1 {
	position: relative;
	font-family: 'Sharp Grotesk DB Cyr Medium 22', serif;

	font-size: 100pt;
	font-weight: bold;
	letter-spacing: -2pt;
	line-height: 115pt;

	max-width: 1000px;
	margin-top: 6rem;
	margin-bottom: 0;
}

.climate .heading h1 .word {
	display: inline-block;
}

.climate h2 span {
	color: lightgreen !important;
}

.climate a {
	color: inherit;
}

.climate a:hover {
	color: darkseagreen;
}

.climate a::after {
	content: ' 🡕';
}

.climate .logos {
	margin: 2rem 0 8rem -10px;
}

.climate .logos img {
	padding: 10px 15px;
	max-height: 60px;
	width: auto;
	opacity: .8;
}

.climate .logos img:hover {
	opacity: 1;
}

.climate .dashboard .card:hover,
.climate .dashboard .card:active,
.climate .dashboard .card:active {
	cursor: url("../static/images/cursors/cursor_new.svg") 7 7, default;
}


/* NOTFOUND
 =================================== */

.notfound {
	/* margin: 22rem auto; */
	margin: 10% auto;
}


/* META
 =================================== */

.page-text p::selection,
.page-text p a::selection,
.page-text h3::selection {
	background-color: #D6D6D6;
}

.page-text h1 {
	font-family: 'Sharp Grotesk DB Cyr Medium 22', sans-serif;
	text-align: center;
	font-size: 62pt;
	margin: 40px 0 20px;
}

.page-text h1 .letter {
	display: inline-block;
}

.page-text p img {
	height: 20px;
	width: auto;
	vertical-align: -.3rem;
	padding-right: .5rem;
}

ul.breadcrumbs,
.page-text ul.breadcrumbs {
	margin-bottom: 40px;
	text-align: center;
	color: #C8C8C8;
	list-style-type: none;
	padding: 0;
	font-family: 'SF Pro Display Regular', sans-serif;
	font-size: 12.5pt;
	text-transform: uppercase;
}

ul.breadcrumbs li,
.page-text ul.breadcrumbs li {
	display: inline-block;

}

ul.breadcrumbs li a,
.page-text ul.breadcrumbs li a {
	color: inherit;
	text-decoration: none;
	position: relative;
	z-index: 99;
}

ul.breadcrumbs li a:hover,
.page-text ul.breadcrumbs li a:hover {
	color: white;
}

ul.breadcrumbs li:not(:last-child)::after,
.page-text ul.breadcrumbs li:not(:last-child)::after {
	content: '/';
	padding: 0 15px;
	color: #777777;
}

.notfound a,
.page-text a,
.story a {
	color: inherit;
	text-decoration: none;
	background-position-y: 20%;
	background-image: linear-gradient(transparent 50%, rgb(47, 47, 47) 50%);
	transition: background 150ms ease;
	background-size: auto 175%;
	padding: 0 2px;
}


.page-text-content.paper a {
	background-image: linear-gradient(white 50%, rgb(211, 211, 211) 50%);
}

.notfound a:hover,
.page-text a:hover,
.story a:hover {
	background-position-y: 80%;
}

.notfound ul.breadcrumbs,
.stories ul.breadcrumbs {
	position: relative;
	display: inline-block;
	background-color: black;
	float: right;
	margin-top: 189px;
	margin-right: -15px;
	padding: 0 25px;
}

.page-text .page-text-content {
	margin: 0 15rem;
}

.page-text .page-text-content p {
	color: #D1D1D1;
	font-family: 'SF Pro Display Thin', sans-serif;
	letter-spacing: .4pt;
	font-size: 14pt;
	text-align: justify;
}

.page-text .page-text-content h2 {
	font-family: 'SF Pro Display Thin', sans-serif;
	letter-spacing: .7pt;
	color: #DFDFDF;
	padding: 25px 0 0;
	font-size: 24pt;
	font-weight: bold;
	text-transform: uppercase;
}

.page-text .page-text-content ul {
	list-style-type: lower-alpha;
}

.page-text .page-text-content ul li::marker {
	content: counter(list-item, lower-alpha) ')  ';
	font-family: 'SF Pro Display Thin', sans-serif;
	letter-spacing: .5pt;
	font-size: 14pt;
	color: #BFBFBF;
}

.page-text .page-text-content h3 {
	font-family: 'SF Pro Display Thin', sans-serif;
	letter-spacing: .5pt;
	color: #DFDFDF;
	padding: 15px 0 0;
	margin: 0 0 -8px;
	font-size: 20pt;
	font-weight: normal;
}

.page-text .page-text-content.paper {
	background-color: white;
	padding: 20px 50px;
	color: black;
	margin-bottom: 1.5rem;
	cursor: default;
}

.page-text .page-text-content.paper a {
	cursor: pointer;
}

.page-text .page-text-content.paper h3 {
	color: black;
	text-transform: uppercase;
	font-family: 'SF Pro Display Heavy', sans-serif;
}

.page-text .page-text-content.paper p {
	color: black;
	text-align: left;
}

.page-text .page-text-content.paper .react-pdf__Document div > div {
	display: none;
}


::-webkit-scrollbar {
	width: 6px; /* For vertical scrollbars */
	height: 6px; /* For horizontal scrollbars */
}

::-webkit-scrollbar-track {
	background: #111111;
}

::-webkit-scrollbar-thumb {
	background: #444444;
	border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
	background: #555555;
}

small {
	display: block;
	padding: 12px 0 0;
	margin: 0;
	font-size: 10pt;
	color: #888888;
}


/* TOOLTIPS
 =================================== */

.tooltip {
	width: auto;
	background-color: #444444;
	color: white;
	text-align: center;
	padding: 5px 20px;
	border-radius: 6px;
	position: absolute;
	z-index: 999;
	margin: 5.5rem 0 0 0;
	font-family: 'SF Pro Display Regular', sans-serif;
	letter-spacing: .05rem;
}

.tooltip:hover {
	pointer-events: none;
}


/* FORMS
 =================================== */

button {
	background-color: white;
	color: #444444;
	font-family: 'SF Pro Display Heavy', sans-serif;
	font-size: 14pt;
	text-align: center;
	padding: 10px 20px;
	border-radius: 10px;
	border: none;
	text-transform: uppercase;
}

button:hover {
	cursor: url("../static/images/cursors/cursor_new_backdrop_small.svg") 30 30, pointer;
}

.dashboard .card button {
	margin: 10px 0 4px -3px;
}


/* PRELOADER
 =================================== */

.preloader {
	margin: 0 auto;
	padding: 35vh 0;
}

.preloader .spinner {
	border: 2px solid #222222;
	border-top: 2px solid white;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin .4s linear infinite;
	margin: 0 auto;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.preloader p {
	margin: 0 auto 0 12px;
	text-align: center;
	font-family: 'SF Pro Display Thin', sans-serif;
	letter-spacing: .1rem;
	text-transform: uppercase;
	color: #666666;
	padding: 20px 0;
	font-size: 9pt;
}


/* FOOTER
 =================================== */

footer {
	position: relative;
	z-index: 99;
	font-family: 'SF Pro Display Medium', sans-serif;
	padding: 80px 0;
	display: block;
	color: #B0B0B0;
}

footer .left,
footer .right {
	padding: 0 20px;
	display: inline-block;
}

footer .right {
	float: right;
	padding-top: 10pt;
}

footer .copyright {
	color: #666666;
	line-height: 16pt;
	transition: color .2s;
}

footer:hover .copyright {
	color: #777777;
	transition: color .2s;
}

footer ul {
	list-style-type: none;
	margin: 0;
}

footer ul li {
	display: inline-block;
	padding: 0 0 0 20px;
}

footer a {
	color: inherit;
	text-decoration: none;
}

footer a:hover {
	color: white;
	border-bottom: 1px solid white;
	/* cursor: url("../static/images/cursors/cursor_new_backdrop_small.svg") 30 30, pointer; */
	cursor: none;
}

footer a:active,
footer a:focus {
	color: #DCDCDC;
	border-bottom-color: #DCDCDC;
}


/* iMessage */

.imessage {
	border-radius: 0.25rem;
	display: flex;
	flex-direction: column;
	font-family: 'San Francisco', sans-serif;
	font-size: 1.25rem;
	margin: 0 auto 1rem;
	max-width: 600px;
}

.imessage p {
	transform: scale(1.25);
	border-radius: 1.15rem;
	line-height: 1.25;
	max-width: 75%;
	padding: 1rem 1.5rem;
	position: relative;
	word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
	bottom: -0.1rem;
	content: "";
	height: 1rem;
	position: absolute;
}

p.from-me {
	background-image: linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(209,209,209,1) 100%);
	color: #444444;
}

p.from-me::before {
	border-bottom-left-radius: 0.8rem 0.7rem;
	border-right: 1rem solid #d1d1d1;
	right: -0.35rem;
	transform: translate(0, -0.1rem);
}

p.from-me::after {
	background-color: black;
	border-bottom-left-radius: 0.5rem;
	right: -40px;
	transform:translate(-30px, -2px);
	width: 10px;
}

p.from-them {
	background-image: linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(209,209,209,1) 100%);
	color: #444444;
}

p.from-them:before {
	border-bottom-right-radius: 0.8rem 0.7rem;
	border-left: 1rem solid #d1d1d1;
	left: -0.35rem;
	transform: translate(0, -0.1rem);
}

p.from-them::after {
	background-color: black;
	border-bottom-right-radius: 0.5rem;
	left: 20px;
	transform: translate(-30px, -2px);
	width: 10px;
}


/* Highlighted */

.highlighted {
	position: relative;
	color: white;
}

.highlighted::before {
	/* Highlight color */
	background-color: black;

	content: "";
	position: absolute;
	width: calc(100% + 100px);
	height: 90%;
	left: -50px;
	bottom: 25px;
	z-index: -1;
}



/* RESPONSIVE DESIGN
 =================================== */

/* -- Full HD */
@media (max-width: 1920px) {
	/* Promo */
	.promo h1 {
		font-size: 100pt;
		line-height: 100pt;
		max-width: 1000px;
	}

	/* General */
	.container {
		max-width: 1325px;
	}

	.container-wide {
		max-width: 1620px;
	}

	/* Dashboard */
	.dashboard .card {
		min-height: 300px;
	}

	.dashboard .card p {
		font-size: 13.5pt;
	}

	.dashboard .card h2 {
		font-size: 48pt;
		line-height: 58pt;
	}

	.dashboard .card p.cutoff {
		max-height: 220px;
	}

	.stories .dashboard .card h3 {
		font-size: 22pt;
		line-height: 30pt;
		margin-top: .5rem;
	}

	.dashboard > .card.article.article-big h3 {
		font-size: 32pt;
		line-height: 42pt;
	}

	/* Portfolio */
	.portfolio .intro {
		margin: 5vh 0 0 10rem;
	}

	.portfolio .intro .intro_container {
		margin-top: 15rem;
	}

	.portfolio .intro .intro_container .title h1 {
		font-size: 87pt;
	}

	.portfolio .intro .intro_container .title p {
		font-size: 28pt;
		margin-top: -9rem;
		margin-right: 20rem;
	}

	.portfolio .right {
		margin-left: -4rem;
	}

	.portfolio .left {
		display: flex;
		margin-left: 12rem;
	}

	/* Hire Document */
	.page-text .page-text-content.resume > div {
		margin-left: -4rem;
		margin-top: -2rem;
		transform: scale(.95);
	}

	 /* Story */
	.story .header {
		margin: 0 10rem;
	}

	.story .header h1 {
		font-size: 74pt;
		margin-left: -15rem;
		max-width: 1000px;
	}

	.story .header .phantom {
		margin-left: -8rem;
	}

	.story .header .image {
		margin-top: -36rem;
		margin-left: 15rem;
		height: 650px;
		width: 1000px;
	}

	.story .header .author {
		margin: -5rem 0 0 -2.5rem;
	}

	.story .article-content {
		margin: 0 2.5rem 5rem;
	}

	.story .meta {
		margin: 2.5rem 2.5rem 8rem;
	}

	.dashboard > .card.article .details {
		margin-bottom: 0;
	}

	.dashboard > .card.article .details ul.tags li {
		margin-bottom: 15px;
	}

	.dashboard > .card.article .timestamp p {
		font-size: 15pt;
	}

	/* Labs */
	.labs .background {
		top: -8rem;
	}

}

/* -- 1620vw */
@media (max-width: 1620px) {

	/* Container Wide */
	.container-wide {
		max-width: 1420px;
	}

	/* Portfolio */
	.portfolio .intro .title h1 {
		font-size: 100pt;
	}

	/* Labs */
	.labs .background {
		background: none;
	}

}

/* -- 1650vw */
@media (max-width: 1600px) {

	/* Story */
	.story .header h1 {
		margin-left: -8.5rem;
		font-size: 65pt;
		max-width: 850px;
	}

	.story .header .phantom {
		font-size: 65pt;
		max-width: 850px;
		margin-left: -6rem;
		margin-top: -12.5rem;
	}

	.story .header .image {
		width: 900px;
	}

	.story .header .author {
		margin-top: -3.5rem;
	}

}

/* -- 1400vw */
@media (max-width: 1400px) {

	/* Mobile Navigation */
	.logo {
		margin-left: 1rem;
	}

	.nav ul {
		display: none;
	}

	.nav .hamburger {
		display: block;
	}

	.mobile-nav {
		display: block;
	}

	/* Grid System */
	.grid {
		display: block;
	}

	.grid > * {
		margin: 2rem 0;
	}

	.grid.article-big > .thumbnail,
	.article > .thumbnail {
		background-image: inherit;
		height: 30rem !important;
		margin: -40px 0 !important;
		background-size: cover !important;
	}

	.grid > .scrolldown {
		width: 100px;
		margin: 0 auto;
		transform: scale(1, -1);
	}

	/* Portfolio */
	.portfolio .intro {
		margin-left: 6rem;
		padding-bottom: 50rem;
	}

	.portfolio .intro .title .roles span:last-of-type {
		display: none;
	}

	.portfolio > section:not(.intro) {
		display: block;
		margin: -15rem 0;
	}

	.portfolio > section.customers {
		margin: -30rem 0 30rem;
	}

	.portfolio > section.impressions {
		display: none;
	}

	.portfolio > section:not(.intro) .title h2 {
		text-align: center;
	}

	.portfolio > section:not(.intro) .title .description {
		margin: 0 auto;
		padding-left: 125px;
		display: block;
		float: none;
	}

	.portfolio > section:not(.intro) .title .logos .logo-disclaimer {
		text-align: center;
		padding: 0 0 20px;
	}

	.portfolio > section:not(.intro) .title .logos {
		margin: 3rem auto 0;
		display: block;
		width: 600px;
		float: none;
		text-align: center;
	}

	.portfolio > section:not(.intro) .title .logos img {
		padding: 0 20px 30px;
	}

	.portfolio > section:not(.intro) .image {
		display: none;
	}

	/* Climate */
	.climate .heading h1 {
		font-size: 80pt;
		line-height: 90pt;
	}

	/* Articles */
	.article {
		padding: 0 !important;
		margin: 6rem 0 3.5rem;
	}

	.article-big {
		margin-top: 0;
	}

	.article-big > .thumbnail {
		border-radius: 15px 15px 0 0 !important;
	}

	.article-big .span-1 h3 {
		margin: 0 -2.5rem !important;
		background-color: transparent !important;
		box-shadow: none !important;
	}

	.article-big .span-1 {
		padding: 20px 50px 30px !important;
	}

	.article-big .span-1,
	.article > div:not(.thumbnail) {
		padding: 20px 50px 60px;
	}

	.article h3 {
		font-size: 28pt !important;
		line-height: 34pt !important;
	}

	/* Article */
	.story .header {
		margin: 2rem 0 0;
	}

	.story .header .phantom {
		display: none;
	}

	.story .header h1 {
		position: static;
		font-size: 64pt;
		display: block;
		width: 100%;
		margin: -2rem 0 2rem 0;
	}

	.story .header .author {
		margin: 1rem 0 2rem;
		display: inline-block;
		position: relative;
	}

	.story .header .image {
		display: block;
		margin: 0;
		width: 100%;
	}

	.story .article-content {
		margin: -2rem 2rem 4.5rem;
	}

	.story .meta {
		margin: 3rem .75rem 8rem;
	}

	.story .meta > div {
		margin: 0 0 20px 20px;
		position: static;
	}

	.story .meta .author-card img {
		height: 55px;
		width: 55px;
		vertical-align: -.25rem;
	}

	.story .meta .author-card .details {
		margin-left: 1.75rem;
	}

	.story .meta > div .details h3 {
		font-size: 18pt;
	}

	.story .meta > div .details p {
		font-size: 11pt;
	}

	.stories.readmore {
		margin-left: 3.5rem;
		margin-right: 3.5rem;
	}

	/* Page Content */
	.page-text-content {
		margin: 0 3rem 2rem !important;
	}

	/* Hire Document */
	.page-text .page-text-content.resume > div {
		margin-left: 7rem;
		margin-top: 7.5rem;
		padding-bottom: 6.5rem;
		transform: scale(1.2);
	}

	/* Container */
	.container {
		margin: 0 3rem;
	}

	/* Dashboard */
	.dashboard .card {
		min-height: 0;
	}

}

/* -- 1250vw */
@media (max-width: 1250px) {

	/* Portfolio */
	.portfolio .intro {
		display: block;
	}

	.portfolio .intro img {
		display: block;
		width: 85%;
		object-fit: cover;
	}

	.portfolio .intro .title {
		margin: -24rem 0 0 0;
	}

	.portfolio .intro .title .name {
		margin: 8rem 0;
	}

	.portfolio .intro .title .name .letter {
		font-size: 77pt;
		line-height: 67pt;
		background-color: black;
		padding: 30px 0;
		margin: -30px 0;
	}

	.portfolio .intro .title .name .letter:last-of-type {
		padding-right: 35px;
	}

	.portfolio .intro .title .roles span {
		font-size: 28pt;
	}

}

/* -- 1080vw */
@media (max-width: 1080px) {

	/* Mobile Navigation */
	.logo {
		margin-left: .5rem;
	}

	/* Promo */
	.promo h1 {
		font-size: 69pt;
		line-height: 80pt;
	}

	.promo button {
		font-size: 14pt;
	}

	.promo button img {
		height: 18px;
	}

	/* Dashboard */
	.dashboard .card h2 {
		font-size: 40pt;
		line-height: 50pt;
	}

	/* Header */
	.stories .header {
		height: 300px;
	}

	.stories .header h1 {
		margin: 4rem 0 2rem;
		padding: 0;
		display: block;
		font-size: 55pt;
		text-align: center;
		z-index: 1;
	}

	.stories .header hr {
		display: none;
	}

	.stories .header .breadcrumbs {
		display: block;
		margin: 0;
		float: none;
	}

	/* Portfolio */
	.portfolio > section:not(.intro) .title h2 {
		font-size: 55pt;
		line-height: 65pt;
	}

	.portfolio > section:not(.intro) .title .description {
		padding-left: 65px;
	}

	.portfolio > section:not(.intro) .title .description p {
		font-size: 14pt;
	}

	/* Page Content */
	.page-text h1 {
		font-size: 40pt;
	}

	/* Hire Document */
	.page-text .page-text-content.resume > div {
		margin-left: -3rem;
		margin-top: -5rem;
		margin-bottom: -10rem;
		transform: scale(.9);
	}

	/* Footer */
	footer {
		padding: 25px 0 3.5rem;
	}

	footer .left,
	footer .right {
		display: block;
		float: none;
	}

	footer .right ul {
		padding: 0;
		margin-bottom: 1.5rem;
	}

	footer .right ul li {
		padding: 0 20px 15px 0;
	}

}

/* -- 800vw */
@media (max-width: 800px) {

	/* Promo */
	.promo {
		height: 100%;
	}

	.promo h1 {
		position: relative;
		margin: 5rem 0;
		max-width: 100%;
		font-size: 50pt;
		line-height: 44pt;
	}

	.promo h1 .wordparent {
		padding: 0 20px 20px 0;
	}

	.promo h1 .word:nth-child(5) {
		margin-left: -1rem;
	}

	.promo button {
		font-size: 11pt;
		padding: 12px 25px;
		margin-bottom: 6rem;
	}

	.promo button img {
		height: 13px;
	}

	/* Container */
	.container {
		margin: 0 1.5rem;
	}

	/* Dashboard */
	.dashboard .card h2 {
		font-size: 30pt;
		line-height: 40pt;
	}

	.dashboard .card p {
		font-size: 13pt;
	}

	.dashboard button {
		position: relative;
		display: block;
		margin-top: 2rem !important;
		width: 100%;
		font-size: 12pt;
		padding: 8px 16px;
	}

	/* Portfolio */
	.portfolio .intro {
		margin: 2rem 0 0 3rem;
	}

	.portfolio .intro img {
		width: 93%;
	}

	.portfolio .intro .title {
		margin-top: -21rem;
	}

	.portfolio .intro .title .name {
		margin: 6.5rem 0;
	}

	.portfolio .intro .title .name:last-of-type {
		margin-bottom: 8rem;
	}

	.portfolio .intro .title .name .letter {
		font-size: 57pt;
		line-height: 57pt;
		padding: 25px 0;
		margin: -25px 0;
	}

	.portfolio .intro .title .roles span {
		font-size: 22pt;
	}

	/* Portfolio Sections */
	.portfolio > section:not(.intro) .title {
		padding: 0 1rem;
	}

	.portfolio > section:not(.intro) .title h2 {
		font-size: 40pt;
		line-height: 48pt;
	}

	.portfolio > section:not(.intro) .title .description {
		padding-left: 65px;
	}

	.portfolio > section:not(.intro) .title .description p {
		font-size: 13pt;
	}

	.portfolio > section:not(.intro) .title .logos {
		max-width: 100%;
	}

	.portfolio > section.customers h3 {
		font-size: 26pt;
	}

	.portfolio > section.customers ul li a img {
		height: 60px;
		width: auto;
	}

	/* Climate */
	.climate .heading h1 {
		font-size: 60pt;
		line-height: 70pt;
	}

	.labs .header h1 {
		font-size: 45pt;
	}

	/* Article */
	.story .header h1 {
		font-size: 50pt;
		text-align: center;
	}

	.story .header .author {
		position: static;
		width: 250px;
		display: block;
		margin: 0 auto 2rem;
	}

	.story .header .image {
		height: 550px;
	}

	/* Page Content */
	.page-text-content {
		margin: 0 1rem 2rem !important;
	}

	/* Hire Document */
	.page-text .page-text-content.resume {
		display: none;
	}

}

/* -- 550vw Mobile Large */
@media (max-width: 525px) {

	/* Portfolio Sections */
	.portfolio > section.customers {
		margin-bottom: 50rem;
	}

	/* Logo */
	.logo img {
		height: 35px;
		margin-top: 5px;
	}

	.logo a:focus img,
	.logo a:active img {
		padding: 16px 15px 8px;
		margin-left: -16px;
		margin-top: -12px;
	}

	/* Promo */
	.promo {
		margin: 0 2rem;
	}

	.promo h1 {
		margin: 0 auto;
		font-size: 33pt;
		line-height: 30pt;
	}

	.promo h1 .word:nth-child(5) {
		margin-left: -.8rem;
	}

	.promo button {
		padding: 10px 20px;
		font-size: 11.5pt;
		text-transform: uppercase;
	}

	.promo button img {
		height: 13px;
	}

	/* Dashboard */
	.dashboard .card h2 {
		font-size: 23pt;
		line-height: 33pt;
	}

	.dashboard .card p {
		font-size: 12pt;
	}

	/* Header */
	.stories .header {
		height: 250px;
	}

	/* Articles */
	.dashboard > .card.article > div.thumbnail {
		height: 15rem !important;
	}

	.article-big .span-1,
	.article > div:not(.thumbnail) {
		padding: 5px 40px 30px !important;
	}

	.dashboard > .card.article .details ul.tags li {
		padding: 5px 15px;
		font-size: 9pt;
		margin: 0 10px 10px 0;
	}

	.dashboard > .card.article h3 {
		font-size: 22pt !important;
		line-height: 30pt !important;
	}

	/* Labs */
	.labs .header h1 {
		font-size: 30pt;
	}

	.labs .disclaimer {
		width: 80%;
		font-size: 10pt;
	}

	/* Article */
	.story .header h1 {
		font-size: 28pt;
	}

	.story .header .author {
		width: 90%;
	}

	.story .header .image {
		height: 300px;
	}

	.story .article-content {
		font-size: 14pt;
		line-height: 22pt;
		margin: -4rem .8rem 4rem;
	}

	.story .meta {
		margin: 3rem 0 8rem;
	}

	.story .meta > div {
		display: block;
		width: 79%;
		max-width: 79%;
		margin: 0 0 1rem;
	}

	.stories.readmore {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.stories.readmore .dashboard .card {
		margin: 4rem 0;
	}

	.story .readmore h3 {
		font-size: 30pt;
		display: block;
		text-align: center;
		padding: 0;
	}

	.story .readmore hr {
		display: none;
	}

	/* Portfolio */
	.portfolio .intro .title {
		margin-top: -15rem;
	}

	.portfolio .intro .title .name .letter {
		font-size: 45pt;
	}

	.portfolio > section:not(.intro) .title h2 {
		font-size: 32pt;
		line-height: 40pt;
		padding: 0 0 15px;
	}

	.portfolio > section:not(.intro) .title .description {
		margin-left: 2rem;
	}

	.portfolio > section:not(.intro) .title .description p {
		font-size: 12pt;
	}

	.portfolio > section:not(.intro) .title .logos img {
		max-height: 40px;
		padding: 0 12px 20px;
	}

	.portfolio > section.customers h3 {
		font-size: 22pt;
		line-height: 35pt;
		max-width: 300px;
		margin: 0 auto;
	}

	.portfolio section.customers ul {
		padding: 20px 0;
	}

	.portfolio section.customers ul li {
		display: block;
		padding: 1.5rem 0;
	}

	.portfolio section.customers h4 {
		font-size: 20pt;
		line-height: 30pt;
		max-width: 300px;
		margin: 4rem auto 0;
	}

	.portfolio section.customers p {
		font-size: 13pt;
	}

	.portfolio section.customers button {
		font-size: 13pt;
		text-transform: uppercase;
		padding: 13px 25px;
	}

	.portfolio section.customers button img {
		height: 15px;
	}

	/* Page Context Paper */
	.page-text .page-text-content.paper {
		padding: 20px 35px;
		margin: 0 -1.5rem !important;
	}


	/* Climate */
	.climate .heading h1 {
		font-size: 40pt;
		line-height: 50pt;
	}

	/* Footer */
	footer {
		font-size: 9pt;
	}

	footer .right {
		font-size: 12pt;
	}

}

/* -- 450vw */
@media (max-width: 450px) {

	/* Portfolio Sections */
	.portfolio > section:not(.intro) .title .description {
		padding-left: 0;
	}

}